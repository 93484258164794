import store from '@/store'
import {
  notProducedAfterSaleList,
  producedAfterSaleList,
  delivedAfterSalelist,
  exportOrder,
  exportOrderNew
} from '@/api/financial/afterSaleProcessingOfOrders'

import { checkPermission, parsePrice } from '@/utils'
import {
  ORDER_TYPE_DIC,
  HANDLE_STATUS_PENDING,
  HANDLE_STATUS_REFUSE_BY_CUSTOMER,
  HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER,
  HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER_BACK,
  HANDLE_STATUS_REFUSE_BY_FINANCE,
  HANDLE_STATUS_REFUSE_BY_FINANCE_BACK,
  HANDLE_STATUS_SUCCESS_BY_CUSTOMER,
  HANDLE_STATUS_SUCCESS_BY_CUSTOMER_MANAGER,
  HANDLE_STATUS_SUCCESS_BY_FINANCE,
  AUDIT_TYPE_SERVER,
  AUDIT_TYPE_SERVER_MANAGER,
  AUDIT_TYPE_FINANCE,
  REFUND_REASON_TYPE_UNPRODUCED,
  REFUND_REASON_TYPE_IN_PRODUCTION,
  REFUND_REASON_TYPE_PRODUCED
} from '@/utils/constant'

export const options = {
  server: {
    auditType: AUDIT_TYPE_SERVER,
    resetMergeData: {
      auditType: AUDIT_TYPE_SERVER
    },

    notProduced: {
      permission: {
        own: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrders:1'),
        info: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrders:1:info')
      }
    },
    produced: {
      permission: {
        own: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrders:2'),
        info: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrders:2:info')
      }
    },
    shipped: {
      permission: {
        own: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrders:3'),
        audit: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrders:3:audit'),
        info: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrders:3:info')
      }
    },

    // 审核状态选项，客服能看到的数据为：未审核、客服已拒绝、客服经理退回
    approveStatus: [
      HANDLE_STATUS_PENDING,
      HANDLE_STATUS_REFUSE_BY_CUSTOMER,
      HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER_BACK
    ],
    canAudit(row) {
      return [HANDLE_STATUS_PENDING, HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER_BACK].includes(row.approveStatus)
    }
  },
  serverManager: {
    auditType: AUDIT_TYPE_SERVER_MANAGER,
    resetMergeData: {
      auditType: AUDIT_TYPE_SERVER_MANAGER
    },

    notProduced: {
      permission: {
        own: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrdersByCustomerServiceManage:1'),
        audit: checkPermission(
          'externaladmin:financialManagement:afterSaleProcessingOfOrdersByCustomerServiceManage:1:audit'
        ),
        info: checkPermission(
          'externaladmin:financialManagement:afterSaleProcessingOfOrdersByCustomerServiceManage:1:info'
        )
      }
    },
    produced: {
      permission: {
        own: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrdersByCustomerServiceManage:2'),
        audit: checkPermission(
          'externaladmin:financialManagement:afterSaleProcessingOfOrdersByCustomerServiceManage:2:audit'
        ),
        info: checkPermission(
          'externaladmin:financialManagement:afterSaleProcessingOfOrdersByCustomerServiceManage:2:info'
        )
      }
    },
    shipped: {
      permission: {
        own: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrdersByCustomerServiceManage:3'),
        audit: checkPermission(
          'externaladmin:financialManagement:afterSaleProcessingOfOrdersByCustomerServiceManage:3:audit'
        ),
        info: checkPermission(
          'externaladmin:financialManagement:afterSaleProcessingOfOrdersByCustomerServiceManage:3:info'
        )
      }
    },

    // 审核状态选项，客服经理能看到的数据为：客服已通过、客服经理已拒绝、财务退回
    approveStatus: [
      HANDLE_STATUS_SUCCESS_BY_CUSTOMER,
      HANDLE_STATUS_REFUSE_BY_CUSTOMER_MANAGER,
      HANDLE_STATUS_REFUSE_BY_FINANCE_BACK
    ],
    canAudit(row) {
      return [HANDLE_STATUS_SUCCESS_BY_CUSTOMER, HANDLE_STATUS_REFUSE_BY_FINANCE_BACK].includes(row.approveStatus)
    }
  },
  finance: {
    auditType: AUDIT_TYPE_FINANCE,
    resetMergeData: {
      auditType: AUDIT_TYPE_FINANCE
    },

    notProduced: {
      permission: {
        own: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrdersByFinance:1'),
        audit: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrdersByFinance:1:audit'),
        info: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrdersByFinance:1:info')
      }
    },
    produced: {
      permission: {
        own: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrdersByFinance:2'),
        audit: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrdersByFinance:2:audit'),
        info: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrdersByFinance:2:info')
      }
    },
    shipped: {
      permission: {
        own: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrdersByFinance:3'),
        audit: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrdersByFinance:3:audit'),
        info: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrdersByFinance:3:info')
      },
      handleOption(option) {
        option.column.splice(
          10,
          0,
          {
            label: '财务审核通过时间',
            prop: 'financeApproveTime',
            search: true,
            searchLabelWidth: 130,
            searchFormSlot: true,
            searchWidth: 555,
            type: 'daterange',
            valueFormat: 'yyyy-MM-dd',
            formatter(row) {
              return row.approveStatus === HANDLE_STATUS_SUCCESS_BY_FINANCE ? row.approveTime : ''
            }
          }
        )
        return option
      }
    },

    // 审核状态选项，财务能看到的数据为：客服经理已通过、财务已拒绝,财务通过
    approveStatus: [
      HANDLE_STATUS_SUCCESS_BY_CUSTOMER_MANAGER,
      HANDLE_STATUS_REFUSE_BY_FINANCE,
      HANDLE_STATUS_SUCCESS_BY_FINANCE
    ],
    canAudit(row) {
      return [HANDLE_STATUS_SUCCESS_BY_CUSTOMER_MANAGER].includes(row.approveStatus)
    }
  }
}

export const list = [
  {
    label: '未生产售后',
    value: 'notProduced',
    getList: notProducedAfterSaleList,
    exportApi(data) {
      data = Object.assign(data, {
        page: {
          pageSize: 0
        }
      })
      return exportOrder({
        ...data,
        applyType: REFUND_REASON_TYPE_UNPRODUCED
      })
    },
    option: {
      menuWidth: 125,
      searchLabelWidth: 88,
      editBtn: false,
      delBtn: false,
      column: [
        {
          label: '退款编号',
          prop: 'tradeNo',
          search: true
        },
        {
          label: '订单号',
          prop: 'orderCode',
          search: true
        },
        {
          label: '分销商',
          prop: 'nickName',
          formatter: nickNameFormatter,
          search: true
        },
        {
          label: '联系方式',
          prop: 'mobile'
        },
        {
          label: '申请原因',
          prop: 'applyReason'
        },
        {
          label: '退款金额',
          prop: 'amount',
          formatter(row, column, value) {
            return parsePrice(value)
          }
        },
        {
          label: '补充描述',
          prop: 'applyReasonDetail'
        },
        {
          label: '审核状态',
          prop: 'approveStatus',
          type: 'select',
          hide: true,
          search: true
        },
        {
          label: '申请时间',
          prop: 'applyTime',
          search: true,
          searchFormSlot: true,
          searchWidth: 555,
          type: 'daterange',
          valueFormat: 'yyyy-MM-dd'
        },
        {
          label: '状态',
          prop: 'approveStatus',
          type: 'select'
        },
        {
          label: '订单类别',
          prop: 'orderType',
          search: true,
          type: 'select',
          dicData: ORDER_TYPE_DIC
        }
      ]
    }
  },
  {
    label: '已生产售后',
    value: 'produced',
    getList: producedAfterSaleList,
    exportApi(data) {
      data = Object.assign(data, {
        page: {
          pageSize: 0
        }
      })
      return exportOrder({
        ...data,
        applyType: REFUND_REASON_TYPE_IN_PRODUCTION
      })
    },
    option: {
      menuWidth: 125,
      searchLabelWidth: 88,
      editBtn: false,
      delBtn: false,
      column: [
        {
          label: '退款编号',
          prop: 'tradeNo',
          search: true
        },
        {
          label: '订单号',
          prop: 'orderCode',
          search: true
        },
        {
          label: '分销商',
          prop: 'nickName',
          formatter: nickNameFormatter,
          search: true
        },
        {
          label: '联系方式',
          prop: 'mobile'
        },
        {
          label: '申请原因',
          prop: 'applyReason',
          type: 'select'
        },
        {
          label: '处理方式',
          prop: 'handleType',
          type: 'select'
        },
        {
          label: '金额变更',
          prop: 'amount',
          formatter(row) {
            return `补运费: ${parsePrice(row.realFreight)}</br>退款: ${parsePrice(row.amount)}`
          }
        },
        {
          label: '收货信息',
          prop: 'consigneeInfo',
          minWidth: 150,
          slot: true
        },
        {
          label: '审核状态',
          prop: 'approveStatus',
          type: 'select',
          hide: true,
          search: true
        },
        {
          label: '申请时间',
          prop: 'applyTime',
          search: true,
          searchFormSlot: true,
          searchWidth: 555,
          type: 'daterange',
          valueFormat: 'yyyy-MM-dd'
        },
        {
          label: '状态',
          prop: 'approveStatus',
          type: 'select'
        },
        {
          label: '订单类别',
          prop: 'orderType',
          search: true,
          type: 'select',
          dicData: ORDER_TYPE_DIC
        }
      ]
    }
  },
  {
    label: '已发货售后',
    value: 'shipped',
    getList: delivedAfterSalelist,
    exportApi(data) {
      data = Object.assign(data, {
        page: {
          pageSize: 0
        }
      })
      return exportOrderNew({
        ...data,
        applyType: REFUND_REASON_TYPE_PRODUCED
      })
    },
    option: {
      menuWidth: 125,
      searchLabelWidth: 88,
      editBtn: false,
      delBtn: false,
      column: [
        {
          label: '退款编号',
          prop: 'tradeNo',
          search: true
        },
        {
          label: '订单号',
          prop: 'orderCode',
          search: true
        },
        {
          label: '分销商',
          prop: 'nickName',
          formatter: nickNameFormatter,
          search: true
        },
        {
          label: '联系方式',
          prop: 'mobile'
        },
        {
          label: '申请原因',
          prop: 'applyReason',
          type: 'select'
        },
        {
          label: '退款金额',
          prop: 'amount',
          formatter(row, column, value) {
            return parsePrice(value)
          }
        },
        {
          label: '问题凭证',
          prop: 'receiptFilePathList',
          slot: true,
          width: 145
        },
        {
          label: '问题描述',
          prop: 'applyReasonDetail'
        },
        {
          label: '审核状态',
          prop: 'approveStatus',
          type: 'select',
          hide: true,
          search: true
        },
        {
          label: '申请时间',
          prop: 'applyTime',
          search: true,
          searchFormSlot: true,
          searchWidth: 555,
          type: 'daterange',
          valueFormat: 'yyyy-MM-dd'
        },
        {
          label: '状态',
          prop: 'approveStatus',
          type: 'select'
        },
        {
          label: '订单类别',
          prop: 'orderType',
          search: true,
          type: 'select',
          dicData: ORDER_TYPE_DIC
        }
      ]
    }
  }
]
list.forEach((item) => {
  store.dispatch('HandleOption', item.option)
})

function nickNameFormatter(row) {
  if (row.$nickName) return row.$nickName
  let { mainNickName, nickName } = row
  return (row.$nickName = mainNickName ? `${mainNickName}-${nickName}` : nickName)
}
