<template>
  <div ref="container" class="app-container">
    <div class="flex-middle-between">
      <listStateGroup v-model="state" :list="list" @change="handleListStateChange"></listStateGroup>
    </div>
    <avue-crud
      ref="crud"
      class="search-form non-expand-icon"
      :option="option"
      :data="tableData"
      :page="tablePage"
      :tableLoading="tableLoading"
      @size-change="sizeChange"
      @current-change="pageChange"
      @search-change="searchChange"
    >
      <template #applyTimeSearchForm="{ form, prop }">
        <SaleDateTime :time.sync="form[prop]" @change="searchChange"></SaleDateTime>
      </template>
      <template #financeApproveTimeSearchForm="{ form, prop }">
        <SaleDateTime :time.sync="form[prop]" @change="searchChange"></SaleDateTime>
      </template>
      <template #menuLeft>
        <el-button v-if="showExport && exportBtn" type="primary" size="small" @click="exportHandler" :loading="loading"
          >导出</el-button
        >
      </template>
      <template #consigneeInfo="{ row }">
        <template v-if="row.handleType === HANDLE_TYPE_POST">
          <div>【{{ row.contactCountryCode }}】 {{ row.contactName }}</div>
          <div>{{ row.contactMobile }}</div>
          <div>{{ row.contactAddress }}</div>
        </template>
      </template>
      <template #receiptFilePathList="{ row }">
        <defaultImg
          disabled
          :preview-src-list="row.receiptFilePathList"
          :carouselList="row.receiptFilePathList"
        ></defaultImg>
      </template>
      <template #menu="{ row }">
        <loadingBtn
          v-if="permission.audit && curOption.canAudit(row)"
          uiid="zd-audit"
          type="text"
          class="menu-btn-item"
          @click="handleExtendDialog(row, 'audit')"
          >审核</loadingBtn
        >

        <loadingBtn type="text" class="menu-btn-item" v-if="permission.info" @click="handleExtendDialog(row)"
          >查看</loadingBtn
        >
      </template>
    </avue-crud>

    <auditDialog :type="curOption.auditType" ref="auditDialog" @success="init"></auditDialog>
  </div>
</template>

<script>
import listStateGroup from '@/views/components/listStateGroup'
import SaleDateTime from '@/components/saleDateTime'
import defaultImg from '@/views/components/defaultImg'
import auditDialog from './module/auditDialog'
import avueCrud from '@/mixins/avueCrud'
import { list, options } from './const'
import { checkPermission } from '@/utils'
import { AUDIT_TYPE_FINANCE, HANDLE_STATUS_DIC, HANDLE_TYPE_POST } from '@/utils/constant'
import { cloneDeep } from 'lodash'
import { exportOrder, exportOrderNew } from '@/api/financial/afterSaleProcessingOfOrders'
import { deepClone } from '@/components/avue/utils/util'
import { exportWrap } from '@/utils'

export default {
  name: 'afterSaleProcessingOfOrders',
  mixins: [avueCrud()],
  components: {
    auditDialog,
    listStateGroup,
    defaultImg,
    SaleDateTime
  },
  data() {
    return {
      HANDLE_TYPE_POST,
      state: '',
      approveStatus: '',
      loading: false,
      exportBtn: checkPermission('externaladmin:financialManagement:afterSaleProcessingOfOrdersByFinance:exportBtn')
    }
  },
  computed: {
    auditType() {
      return this.$route.path.split('/').pop() || 'server'
    },
    curOption({ auditType }) {
      return options[auditType] || options.server
    },
    resetMergeData({ curOption }) {
      return {
        ...curOption.resetMergeData,
        orderItems: [
          // {
          //   column: 'handle_status',
          //   asc: true
          // },
          {
            column: 'apply_time',
            asc: false
          }
        ]
      }
    },
    list({ curOption }) {
      return cloneDeep(list).filter((item) => {
        let approveStatusColumn = item.option.column.find((column) => column.prop === 'approveStatus') || {}
        approveStatusColumn.dicData = HANDLE_STATUS_DIC.filter((dicItem) =>
          curOption.approveStatus.includes(dicItem.value)
        )

        return curOption[item.value].permission.own
      })
    },
    curItem({ list, state }) {
      return list.find((item) => item.value === state)
    },
    getList({ curItem }) {
      return curItem.getList
    },
    permission({ curOption, state }) {
      return curOption[state].permission
    },
    option({ curOption, curItem }) {
      const { handleOption } = curOption[curItem.value] || {}
      if (handleOption) return handleOption(curItem.option)
      return curItem.option
    },
    showExport() {
      if (this.$route.name === '售后审核_财务' || this.$route.name === '售后审核财务') {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    list: {
      handler(n) {
        this.state = n[0]?.value
      },
      immediate: true
    }
  },
  methods: {
    handleListStateChange() {
      this.tableData = [] // 置空，减少切换时的DOM消耗
      this.searchChange()
    },
    async handleExtendDialog(row, type) {
      await this.$refs.auditDialog.dialogOpen(
        row,
        this.state,
        type === 'audit' && this.curOption.auditType === AUDIT_TYPE_FINANCE
          ? 'final'
          : type === 'audit'
          ? true
          : undefined
      )
    },
    async exportHandler() {
      let { exportApi, label } = this.curItem
      await exportWrap(exportApi(this.postData), `售后审核_${label}`)
      // this.loading = true
      // try {
      //   let { exportApi, label } = this.curItem
      //   let res = await exportApi(this.postData)
      //   let url = URL.createObjectURL(res)
      //   if (res instanceof Blob) {
      //     let aLink = document.createElement('a')
      //     aLink.setAttribute('download', `售后审核_${label}`)
      //     aLink.href = url
      //     aLink.click()
      //     this.$message.success('操作成功')
      //   } else {
      //     this.$message.error('操作失败')
      //   }
      // } catch {}
      // this.loading = false
    }
  }
}
</script>

<style lang="scss"></style>
